import React from "react";
import { ShortListTable } from "./ShortListTable";

export const ShortL = () => {
     
    return (
        <>
            <div className={`container-fluid`}>
                <ShortListTable />
            </div>
        </>
    );
};
