/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
import { BsFileEarmarkText } from "react-icons/bs";

export const TotalInvoiceTile = () => {
  return (
    <>
      <div className={`card card-custom gutter-b ${cs.invoiceTile} ${cs.dashTileHeight}`}  >
        <a  href="#" >
            <div className="card-body">
                <div className={cs.bgIcon}>
                    <BsFileEarmarkText className={cs.fileIcon} />
                </div>
                <p className="text-light font-weight-bold font-size-lg mt-15 mb-0">Total Invoice Generated</p>
                <div className="text-white font-weight-bold display-3 mt-1"> 20 </div>
            </div>
            </a>
      </div>
    </>
  );
}
