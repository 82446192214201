const TracklistMockdata= [
    {
        id:"1",
        type:"F",
        certi:"GIA : 123456789",
        stockId:"RK-123456789",
        description:"Pear 2.00 ct Fancy Light Purplish Pink VVS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"2",
        type:"F",
        certi:"GIA : 123498521",
        stockId:"RK-432156789",
        description:"Round 1.00 ct Fancy Light Pinkish Pink VS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"3",
        type:"W",
        certi:"GIA : 432198521",
        stockId:"RK-541256789",
        description:"Heart 1.00 ct Fancy Light Yellowis Yellow VS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"4",
        type:"LF",
        certi:"GIA : 654896321",
        stockId:"RK-784563214",
        description:"Round 5.00 ct Fancy Light Brown Brownish I2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"5",
        type:"F",
        certi:"GIA : 123456789",
        stockId:"RK-123456789",
        description:"Pear 2.00 ct Fancy Light Purplish Pink VVS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"6",
        type:"P",
        certi:"GIA : 123498521",
        stockId:"RK-432156789",
        description:"Round 1.00 ct Fancy Light Pinkish Pink VS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"7",
        type:"W",
        certi:"GIA : 432198521",
        stockId:"RK-541256789",
        description:"Heart 1.00 ct Fancy Light Yellowis Yellow VS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"8",
        type:"L",
        certi:"GIA : 654896321",
        stockId:"RK-784563214",
        description:"Round 5.00 ct Fancy Light Brown Brownish I2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"9",
        type:"F",
        certi:"GIA : 123456789",
        stockId:"RK-123456789",
        description:"Pear 2.00 ct Fancy Light Purplish Pink VVS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"16",
        type:"F",
        certi:"GIA : 123498521",
        stockId:"RK-432156789",
        description:"Round 1.00 ct Fancy Light Pinkish Pink VS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"10",
        type:"W",
        certi:"GIA : 432198521",
        stockId:"RK-541256789",
        description:"Heart 1.00 ct Fancy Light Yellowis Yellow VS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"11",
        type:"L",
        certi:"GIA : 654896321",
        stockId:"RK-784563214",
        description:"Round 5.00 ct Fancy Light Brown Brownish I2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"12",
        type:"F",
        certi:"GIA : 123456789",
        stockId:"RK-123456789",
        description:"Pear 2.00 ct Fancy Light Purplish Pink VVS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"13",
        type:"F",
        certi:"GIA : 123498521",
        stockId:"RK-432156789",
        description:"Round 1.00 ct Fancy Light Pinkish Pink VS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"14",
        type:"W",
        certi:"GIA : 432198521",
        stockId:"RK-541256789",
        description:"Heart 1.00 ct Fancy Light Yellowis Yellow VS2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    {
        id:"15",
        type:"L",
        certi:"GIA : 654896321",
        stockId:"RK-784563214",
        description:"Round 5.00 ct Fancy Light Brown Brownish I2  EX  EX NONE",
        dollarprice:"$12010.50",
        pricehike:"$12.00",
    },
    
]
export default TracklistMockdata;