/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState,useMemo } from "react";
import axios from "axios";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import {ImagePopup} from "./../tables/imagePopup";
import {VideoPopup} from "./../tables/videoPopup";
import { FaAward } from 'react-icons/fa'
import {FETCH_CONSUMER_SHORTLIST_DATA} from "./../../../env_config"

export const ExpandedDetails = (data) => {

    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)

    const [consumerData, setConsumerData] = useState([]);
    useEffect(() => {
        const finalData = `{  "customerId": ${user.CustomerId}, "ConsumerId":${data.data.ConsumerId} }`;
        const getShortListData = async () =>  {
        
            axios({
            method: 'POST',
            url: FETCH_CONSUMER_SHORTLIST_DATA,
            headers: { 
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
                },
                data: finalData,
            })
                .then( (res) =>{
                setConsumerData( res.data.Result)
            })
            .catch(function (res) {
                console.log(res)
            });
        };
            
            getShortListData();
           
        }, [user.CustomerId , data.data.ConsumerId]);

    if(consumerData === undefined ){
        return
    }
   
    return (
        <>
            <div className="sh_expand">
            {/* {consumerData} */}
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th className="text-center">Diamond Type</th>
                            <th className="text-center">Media</th>
                            <th className="text-center">Stock No.</th>
                            <th className="text-center">Certificate</th>
                            <th className="text-center">Shape</th>
                            <th className="text-center">Color</th>
                            <th className="text-center">Carat</th>
                            <th className="text-center">Cut</th>
                            <th className="text-center">Clarity</th>
                            <th className="text-center">Polish</th>
                            <th className="text-center">Symm.</th>
                            <th className="text-center">Flou.</th>
                            <th className="text-center">Lab</th>
                            <th className="text-center">Currency</th>
                            <th className="text-center">$/CT</th>
                            <th className="text-center">Price</th>
                            <th className="text-center">Mes.</th>
                            <th className="text-center">Depth %</th>
                            <th className="text-center">Table %</th>
                        </tr>
                    </thead>
                    {consumerData? <tbody>
                        { consumerData.map((val, index) => {
                            return (
                                <tr key={val.ProductId}>
                                    <td className="text-center">
                                        {val.WLDiamondType==="N"?("Natural"):("Lab")}
                                    </td>
                                    <td className="text-center d-flex">
                                        <span>
                                            {val.AWS_Image === "" ? (
                                                <span style={{ width: "14px" }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            ) : (
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Image
                                                        </Tooltip>
                                                    }
                                                >
                                                    <ImagePopup Image={val.AWS_Image} Shape={val.C_Shape} />
                                                </OverlayTrigger>
                                            )}
                                        </span>
                                        <span>
                                            {val.video === "" ? (
                                                <span style={{ width: "14px" }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            ) : (
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Video
                                                        </Tooltip>
                                                    }
                                                >
                                                    <VideoPopup ProductId={val.ProductId} WLDiamondType={val.WLDiamondType} Certi_No={val.Certi_No} Video={val.VideoLink}/>
                                                </OverlayTrigger>
                                            )}
                                        </span>
                                        <span>
                                            {val.Certificate_link === "" ? (
                                                <span style={{ width: "14px" }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            ) : (
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Certificate
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a
                                                        className="text-primary mx-2 text-center"
                                                        href={
                                                            val.Lab === "IGI" ? `https://www.igi.org/viewpdf.php?r=${val.Certi_No}`  :  val.Lab === "GIA"  ? `https://www.gia.edu/report-check?reportno=${val.Certi_No}` : val.Lab === "HRD" ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${val.Certi_No}` : val.Lab === "GCAL" ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${val.Certi_No}` : val.Certificate_link
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <FaAward />
                                                    </a>
                                                </OverlayTrigger>
                                            )}
                                        </span>
                                    </td>
                                    <td className={`${val.WLDiamondType==="N"?("natTab"):("labTab")} text-center text-nowrap text-primary`}>{val.WLDiamondType}-{val.ProductId}</td>
                                    <td className="text-center">{val.Certi_No}</td>
                                    <td className="text-center">{val.C_Shape}</td>
                                    <td className="text-center">{val.C_Color}</td>
                                    <td className="text-center">{val.C_Weight.toFixed(2)}</td>
                                    <td className="text-center">{val.C_Cut}</td>
                                    <td className="text-center">{val.C_Clarity}</td>
                                    <td className="text-center">{val.C_Polish}</td>
                                    <td className="text-center">{val.C_Symmetry}</td>
                                    <td className="text-center">{val.C_Fluorescence}</td>
                                    <td className="text-center">{val.Lab}</td>
                                    <td className="text-center">{val.Currency}</td>
                                    <td className="text-right">
                                        <span>{val.Currency === "USD" ? "$"  :  val.Currency === "INR" ? "₹" : val.Currency === "HKD" ? "$" : val.Currency === "AUD" ? "$" : val.Currency === "EUR" ? "€" : val.Currency === "CAD" ? "$" : val.Currency === "CNY" ? "¥" : val.Currency === "GBP" ? "£" : val.Currency === "NZD" ? "$" : val.Currency === "JPY" ? "¥" : user.Currency === "CHF" ? "₣" : '' }</span>
                                        {val.MarkUpRate.toFixed(2)}
                                    </td>
                                    <td className="text-right">
                                        <span>{val.Currency === "USD" ? "$"  :  val.Currency === "INR" ? "₹" : val.Currency === "HKD" ? "$" : val.Currency === "AUD" ? "$" : val.Currency === "EUR" ? "€" : val.Currency === "CAD" ? "$" : val.Currency === "CNY" ? "¥" : val.Currency === "GBP" ? "£" : val.Currency === "NZD" ? "$" : val.Currency === "JPY" ? "¥" : user.Currency === "CHF" ? "₣" : '' }</span>
                                        {val.MarkUpPrice.toFixed(2)}
                                    </td>
                                    <td className="text-center">{val.C_Length}x{val.C_Width}x{val.C_Depth}</td>
                                    <td className="text-center">{val.C_DefthP}</td>
                                    <td className="text-center">{val.C_TableP}</td>
                                </tr>
                            );
                        })}
                    </tbody> :""}
                </Table>
            </div>
        </>
    );
};
