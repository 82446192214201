/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
import {Tabs,Tab} from 'react-bootstrap'

export const DashboardSearchTab = () => {
  return (
    <>
      <div className={`card card-custom gutter-b`}  >
        <div className="card-body dasboardSearch">
          <span className="small_title">SEARCH</span>
          <Tabs defaultActiveKey="Diamond" id="search-tab">
              <Tab eventKey="Diamond" title="Diamond">
                <div className="row">
                  <div className="col-12">
                    <p className="mt-5 medium_title">Natural Diamonds</p>
                    <div className="row">
                      <div className="col-md-4">
                        <a href="#">
                          <div className="diamondBox">
                            <div className="wbg">
                              <span className="white_dia_icon"></span>
                            </div>
                            <p>White Diamonds</p>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#">
                          <div className="diamondBox">
                            <div className="fbg">
                              <span className="fancy_dia_icon"></span>
                            </div>
                            <p>Fancy Color Diamonds</p>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#">
                          <div className="diamondBox">
                            <div className="pbg">
                              <span className="parcel_dia_icon"></span>
                            </div>
                            <p>Parcel Color Diamonds</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="mt-10 medium_title">Lab Diamonds</p>
                    <div className="row">
                      <div className="col-md-4">
                        <a href="#">
                          <div className="diamondBox">
                            <div className="lbg">
                              <span className="lab_dia_icon"></span>
                            </div>
                            <p>White Diamonds</p>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#">
                          <div className="diamondBox">
                            <div className="lfbg">
                              <span className="lab_fancy_dia_one"></span>
                            </div>
                            <p>Fancy Color Diamonds</p>
                          </div>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#">
                          <div className="diamondBox">
                            <div className="lpbg">
                              <span className="lab_parcel_dia_one"></span>
                            </div>
                            <p>Parcel Color Diamonds</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Jewelery" title="Jewelery" disabled>
                Jewelery content
              </Tab>
              <Tab eventKey="Gemstone" title="Gemstone" disabled>
                  Gemstone content
              </Tab>
          </Tabs>
        </div>
    </div>
    </>
  );
}
