import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";


export const MockShortListColumn =[
    {
        Header:'ID',
        accessor:'ConsumerId',
        className:"align-middle text-center",
        show: false
    },
    
    {
        Header:'List Name',
        accessor:'ListName',
        className:"align-middle text-center",
    },
    {
        Header:'Consumer Name',
        accessor:'ConsumerName',
        className:"align-middle text-center",
    },
    {
        Header:'Email',
        accessor:'EMail',
        className:"align-middle text-center",
    },
    {
        Header:'Contact Number',
        accessor:'ContactNo',
        className:"align-middle text-center",
    },
   
    {
        Header:'No. of Stones',
        accessor:'ShortListedStones',
        className:"text-center align-middle",
    },
    {
        Header:'Total Carat',
        accessor:'TotalCarats',
        className:"text-center align-middle",
    },
    {
        Header:'Notes',
        minwidth: 100,
        accessor:'Notes',
        className:"align-middle text-center",
        Cell: (row) => {
            return (
                <>
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip id="tooltip">{row.value}</Tooltip>
                        }
                    >
                        <span
                            className="d-inline-block text-truncate text-dark"
                            style={{ maxWidth: "120px" }}
                        >
                            {row.value}
                        </span>
                    </OverlayTrigger>
                </>
            );
        },
    },
    {
        Header:'Created Date',
        accessor:'CreatedAt',
        className:"text-center align-middle",
    },
]