/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {FaLongArrowAltDown,FaLongArrowAltUp} from "react-icons/fa"
import { Tracklist } from "./tracklist/Tracklist";
import TracklistMockdata from "./tracklist/TracklistMockdata";
export const DashboardTracklist = () => {
  return (
    <>
      <div className={`card card-custom gutter-b`}  >
        <div className="card-body dasboardSearch">
            <div className="d-flex justify-content-between ">
                <div>
                    <span className="small_title">MY TRACKLIST</span>
                    <div>
                        <a href="#" className="text-success"><FaLongArrowAltDown className="text-success" />Price Drop</a>
                        <a href="#" className="text-danger"><FaLongArrowAltUp className="text-danger" />Price Hike</a>
                    </div>
                </div>
                <a href="#" className="">see all</a>
            </div>
            <div className="tracklistdata">
                {TracklistMockdata.map((val) => {
                    return(
                        <Tracklist 
                            key={val.id}
                            type={val.type}
                            certi={val.certi}
                            stockId={val.stockId}
                            description={val.description}
                            dollarprice={val.dollarprice}
                            pricehike={val.pricehike}
                        />
                    );    
                })}
            </div>
        </div>
    </div>
    </>
  );
}
