import React from "react";
import {
    IncompleteProfileTile,
    ItemsInCartTile,
    TotalOrderPlaceTiles,
    TotalInvoiceTile,
    DiamondRequestTile,
    DashboardSearchTab,
    DashboardTracklist,
    RecentDiamondTile,
    SavedSearchtile,
    SalesExecutiveTile
} from "../widgets";

export const Dashboard = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-12">
                            <IncompleteProfileTile />
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <ItemsInCartTile  />
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <TotalOrderPlaceTiles  />
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <TotalInvoiceTile />
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <DiamondRequestTile />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <DashboardSearchTab />
                </div>
                <div className="col-md-6">
                    <DashboardTracklist />
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <RecentDiamondTile />
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-12">
                            <SavedSearchtile />
                        </div>
                        <div className="col-12">
                            <SalesExecutiveTile />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}