/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export const SalesExecutiveTile = () => {
  return (
    <>
      <div className={`card card-custom gutter-b`}  >
          <div className="card-body salesExecutiveTile">
              <span className="small_title">YOUR SALES EXECUTIVE</span>
          </div>
      </div>
    </>
  );
}