/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {FaLongArrowAltUp} from "react-icons/fa"
export const Tracklist = (props) => {
    return (
        <>
            <div className="row border-bottom">
                <div className="col-md-8 mb-3 mt-2">
                    <div>
                        <span className={`${props.type}_diamond mr-2 small`}>{props.type}</span>
                        <a href="#" className="mr-2 small">{props.certi}</a>
                        <span className="small"> <span className="font-weight-bold small">Stock Id:</span>{' '}{props.stockId}</span>
                    </div>
                    <div>
                        <span className="text-dark font-weight-bold"> {props.description} </span>
                    </div>
                </div>
                <div className="col-md-4 text-right">
                    <div className="small"> {props.dollarprice}</div>
                    <div className="text-danger font-weight-bold"><FaLongArrowAltUp /> {props.pricehike}</div>
                </div>
            </div>
        </>
    )
}