import { openDB, deleteDB, wrap, unwrap } from 'idb';
const version = 1
const storeName = "jsondata"
const dbName = "searchdiamonds"
export async function StoreDiamondDB(data){
    if(data && data.length !==0){
        // console.log("StoreDiamondDB")
        const db = await openDB(dbName,version)
        const updatedata = await db.transaction(storeName,"readwrite").objectStore(storeName).put(data,"jsondata")
        // console.log(updatedata,"StoreDiamondDB Completed")
    }
}
export async function GetDiamondDB(){
    // console.log("GetDiamondDB1")
    const db = await openDB(dbName, version, {
        upgrade(db, oldVersion, newVersion, transaction) {
          const store = db.createObjectStore(storeName)
          // console.log(store,"GetDiamondDB")
        }
      })
      const getkeys = await db.transaction(storeName,"readwrite").objectStore(storeName).get("jsondata")
      // console.log(getkeys,"getkeys")

      return getkeys
}