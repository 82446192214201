/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
// import {toAbsoluteUrl} from "../../../_helpers";
import { TiShoppingCart } from "react-icons/ti";

export const ItemsInCartTile = () => {
  return (
    <>
      <div className={`card card-custom gutter-b ${cs.cartTile} ${cs.dashTileHeight}`}  >
        <a  href="#" >
            <div className="card-body">
                <div className={cs.bgIcon}>
                    <TiShoppingCart className={cs.cartIcon} />
                </div>
                <p className="text-light font-weight-bold font-size-lg mt-15 mb-0">Items in Your Cart</p>
                <div className="text-white font-weight-bold display-3 mt-1"> 10 </div>
            </div>
            </a>
      </div>
    </>
  );
}
