import React, { Suspense } from "react";
import { Redirect, Switch, } from "react-router-dom";
// import { useSelector } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { DemoTablePage } from "./pages/DemoTablePage";
import { ShortListPage } from "./pages/ShortListPage";
import { ProductDetailPage } from "./pages/ProductDetailPage";
import { GridPage } from "./pages/GridPage";
import "../assets/css/BackendStyle.css"
import { SearchDiamond  } from "../_metronic/_partials";
import IdleTimerContainer from "./IdleTimerContainer"
// import * as auth from "../Auth";


export default function BasePage() {
 
  // const { user } = useSelector((state) => state.auth);

  const [user, setUser] = React.useState([]);
  // const [id, setID] = React.useState();

  React.useEffect(() => {
    const userData = localStorage.getItem("userData");
    setUser(JSON.parse(userData))
    // const cid = localStorage.getItem("C_id");
    // setID(cid);
  },[])
  
  if(user === undefined ){
    return
  }
  // console.log("id",id)
  return (
    <>
    <IdleTimerContainer /> 
      <style>
            {`
                ::selection {
                    color: white;
                    background: ${user.NaturalPrimaryColor};
                }
                body::-webkit-scrollbar {
                  height: 10px;
                  width:  10px;
                }
                /*Track */
                body::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                }
                /*Handle */
                body::-webkit-scrollbar-thumb {
                    background: ${user.NaturalPrimaryColor}42;
                    border-radius: 10px;
                    box-shadow: inset 0 0 5px 3px ${user.NaturalPrimaryColor};
                }
                body::-webkit-scrollbar-thumb:hover {
                    background: ${user.NaturalPrimaryColor}8f;
                    scrollbar-color: ${user.NaturalPrimaryColor}8f rgb(0 33 115 / 56%);
                }

                .table-responsive::-webkit-scrollbar {
                  height: 10px;
                  width:  10px;
                }
                /*Track */
                .table-responsive::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                }
                /*Handle */
                .table-responsive::-webkit-scrollbar-thumb {
                    background: ${user.NaturalPrimaryColor}42;
                    border-radius: 10px;
                    box-shadow: inset 0 0 5px 3px ${user.NaturalPrimaryColor};
                }
                .table-responsive::-webkit-scrollbar-thumb:hover {
                    background: ${user.NaturalPrimaryColor}8f;
                    scrollbar-color: ${user.NaturalPrimaryColor}8f rgb(0 33 115 / 56%);
                }
                .natTab .search_accordion .card .card-header {
                    background-color: ${user.NaturalPrimaryColor}!important;
                    color: ${user.NaturalFontColor};
                }
                .natTab .search_btn_check > input:checked ~ .selectBox {
                    background-color:${user.NaturalPrimaryColor};
                    color: ${user.NaturalFontColor};
                }
                .natTab .selectBox:hover {
                    border: 1px solid ${user.NaturalPrimaryColor}!important;
                }
                .natTab .search_btn_check > .selectBox {
                    background-color: ${user.NaturalPrimaryColor}10;
                    border: 1px solid ${user.NaturalPrimaryColor}40;
                }
                .natTab .search_btn_check > input:checked ~ .selectBox span {
                  color: ${user.NaturalFontColor};
                }
                .natTab .text-primary{color: ${user.NaturalPrimaryColor}!important;}
                .natTab.text-primary{color: ${user.NaturalPrimaryColor}!important;}
                .natTab .btn.btn-primary {
                  color:${user.NaturalFontColor}!important;
                  background-color: ${user.NaturalPrimaryColor};
                  border-color: ${user.NaturalPrimaryColor};
                }

                .natTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .natTab .btn.btn-primary:focus:not(.btn-text),  .natTab .btn.btn-primary.focus:not(.btn-text) {
                  color:${user.NaturalFontColor}!important!important;
                  background-color: ${user.NaturalPrimaryColor}cc;
                  border-color: ${user.NaturalPrimaryColor}cc;
                }

                .natTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .natTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .natTab .show > .btn.btn-primary.dropdown-toggle, .natTab .show .btn.btn-primary.btn-dropdown {
                  color:${user.NaturalFontColor}!important;
                  background-color: ${user.NaturalPrimaryColor}cc;
                  border-color: ${user.NaturalPrimaryColor}cc;
              }

                .labTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${user.NaturalFontColor};
                }

                .labTab .search_accordion .card .card-header {
                  background-color: ${user.LabPrimaryColor}!important;
                  color: ${user.LabFontColor};
                }
                .labTab .search_btn_check > input:checked ~ .selectBox {
                    background-color:${user.LabPrimaryColor};
                    color: ${user.LabFontColor};
                }
                .labTab .selectBox:hover {
                    border: 1px solid ${user.LabPrimaryColor}!important;
                }
                .labTab .search_btn_check > .selectBox {
                    background-color: ${user.LabPrimaryColor}10;
                    border: 1px solid ${user.LabPrimaryColor}40;
                }
                .labTab .search_btn_check > input:checked ~ .selectBox span {
                  color: ${user.LabFontColor};
                }

                .labTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${user.LabFontColor};
                }

                .labTab .search_btn_check > input:checked ~ .selectBox g rect[fill] {
                  stroke: ${user.LabFontColor}00;
                }
                .labTab .text-primary{color: ${user.LabPrimaryColor}!important;}
                .labTab.text-primary{color: ${user.LabPrimaryColor}!important;}
                .labTab .btn.btn-primary {
                  color:${user.LabFontColor}!important;
                  background-color: ${user.LabPrimaryColor};
                  border-color: ${user.LabPrimaryColor};
                }

                .labTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .labTab .btn.btn-primary:focus:not(.btn-text),  .labTab .btn.btn-primary.focus:not(.btn-text) {
                  color:${user.LabFontColor}!important;
                  background-color: ${user.LabPrimaryColor}cc;
                  border-color: ${user.LabPrimaryColor}cc;
                }

                .labTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .labTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .labTab .show > .btn.btn-primary.dropdown-toggle, .labTab .show .btn.btn-primary.btn-dropdown {
                  color:${user.LabFontColor}!important;
                  background-color: ${user.LabPrimaryColor}cc;
                  border-color: ${user.LabPrimaryColor}cc;
                }
            `}
        </style>
        <Suspense fallback={<LayoutSplashScreen />}>
          
          <Switch>
           
            <Redirect exact from="/" to="/searchdiamond" />  
            <ContentRoute path="/dashboard" component={DashboardPage} />
            <ContentRoute path="/builder" component={BuilderPage} />
            {/* <ContentRoute path="/my-page" component={MyPage} /> */}
            <ContentRoute path="/demotable" component={DemoTablePage} />
            <ContentRoute path="/grid" component={GridPage} />
            <ContentRoute path="/details" component={ProductDetailPage} />
            <ContentRoute path="/shortlist" component={ShortListPage} />
            {/* <ContentRoute path="/selectsession" component={SelectSession} /> */}
            <ContentRoute path="/searchdiamond" component={SearchDiamond} />
            <Redirect to="error/error-v1" />
          </Switch>
        </Suspense>
    </>

  );
}
