import React from "react";
import { Field } from "formik";

export const Flour = () => {
    const flourData = ([
        {
            id: 1,
            value:"NON",
            flour:"NONE"
        },
        {
            id: 2,
            value:"FNT",
            flour:"FAINT"
        },
        {
            id: 3,
            value:"VSLT",
            flour:"VERY SLIGHT"
        },
        {
            id: 4,
            value:"SLT",
            flour:"SLIGHT"
        },
        {
            id: 5,
            value:"MED",
            flour:"MEDIUM"
        },
        {
            id: 6,
            value:"STG",
            flour:"STRONG"
        },
        {
            id: 7,
            value:"VST",
            flour:"VERY STRONG"
        }
    ]);

    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6">FLUOROSENCE INTENSITY</p>
                </div>
                <div className="col-lg-10 d-flex flex-wrap">
                    {flourData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                        <Field type="checkbox" name="Fluorescence_Intensity" id={`${item.flour}_flour`} value={item.value}/>
                            <div className="flourBox selectBox">
                                <span>{item.flour}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};