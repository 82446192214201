import React from "react";
import {RecentDiamondCard} from "./RecentDiamond/RecentDiamondCard"
import RecentDiamondCardMock from "./RecentDiamond/RecentDiamondCardMock"

export const RecentDiamondTile = () => {
    return (
        <>
            <div className={`card card-custom gutter-b`}>
                <div className="card-body recentlyViewedDash">
                    <span className="small_title">RECENTLY VIEWED DIAMONDS</span>
                    <div className="row mt-5">
                        {RecentDiamondCardMock.map((val) => {
                        return(
                            <RecentDiamondCard 
                                id={val.id}
                                shape={val.shape}
                                stock_status={val.stock_status}
                                location={val.location}
                                certi={val.certi}
                                description={val.description}
                                suplier={val.suplier}
                                dollarprice={val.dollarprice}
                                Total={val.Total}
                                discount={val.discount}
                            />
                        );    
                    })}
                    </div>
                </div>
            </div>
        </>
    );
};
