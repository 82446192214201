const RecentDiamondCardMock= [
    {
        id:"1",
        shape:"round",
        stock_status:"Instock",
        location:"india",
        certi:"GIA : 123498521",
        description:"round 2.00 ct Fancy Light Purplish Pink VVS2  EX  EX NONE",
        suplier:"Vru star HK Limited",
        dollarprice:"$12010.50",
        Total:"$12010.50",
        discount:"10.02%",
    },
    {
        id:"2",
        shape:"heart",
        stock_status:"Outofstock",
        location:"hongkong",
        certi:"GIA : 123498521",
        description:"Heart 2.00 ct Fancy Light Purplish Pink VVS2  EX  EX NONE",
        suplier:"Rk Exports",
        dollarprice:"$12010.50",
        Total:"$12010.50",
        discount:"10.02%",
    },
    {
        id:"3",
        shape:"oval",
        stock_status:"Instock",
        location:"india",
        certi:"GIA : 123498521",
        description:"ovel 2.00 ct Fancy Light Purplish Pink VVS2  EX  EX NONE",
        suplier:"Diamondsoncall",
        dollarprice:"$1023.50",
        Total:"$15346.50",
        discount:"10.02%",
    },
    {
        id:"4",
        shape:"heart",
        stock_status:"Outofstock",
        location:"hongkong",
        certi:"GIA : 123498521",
        description:"Heart 2.00 ct Fancy Light Purplish Pink VVS2  EX  EX NONE",
        suplier:"Rk Exports",
        dollarprice:"$12010.50",
        Total:"$12010.50",
        discount:"10.02%",
    }
    
]
export default RecentDiamondCardMock;