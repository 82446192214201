/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo ,useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";

export function QuickUserToggler() {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [FullScreen,setFullScreen] = useState(false)
  console.log(FullScreen,"FullScreen")
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);
  function fullscreen() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
  }
  
  document.addEventListener('fullscreenchange', exitHandler);
  document.addEventListener('webkitfullscreenchange', exitHandler);
  document.addEventListener('mozfullscreenchange', exitHandler);
  document.addEventListener('MSFullscreenChange', exitHandler);
  
  function exitHandler() {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        console.log("this is not fullscreen");
        setFullScreen(false)
    } else {
        console.log("this fullscreen");
        setFullScreen(true)

    }
  }
  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <>
                <span className="symbol symbol-35 symbol-light-primary">
                  <span className="symbol-label font-size-h5 font-weight-bold">
                    {user.fullname[0]}
                  </span>
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>
      )}
              <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">{!FullScreen?"Enter Full Screen":"Exit Full Screen"}</Tooltip>}
        >
          <div className="topbar-item" onClick={() => fullscreen()}>
            <div
              className="btn btn-icon w-auto btn-clear d-flex align-items-center btn-sm mr-3"
            >
              <>
                  {!FullScreen?<i class="fas fa-expand text-primary"></i>:<i class="fas fa-solid fa-compress text-primary"></i>}
                {/* <span className="symbol symbol-35 symbol-light-primary">
                  <span className="symbol-label font-size-h5 font-weight-bold">

                  </span>
                </span> */}
              </>
            </div>
          </div>
        </OverlayTrigger>
      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
