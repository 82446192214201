/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
import { FaHandHolding } from "react-icons/fa";

export const TotalOrderPlaceTiles = () => {
  return (
    <>
      <div className={`card card-custom gutter-b ${cs.OrderPlaceTiles} ${cs.dashTileHeight}`}  >
        <a  href="#" >
            <div className="card-body">
                <div className={cs.bgIcon}>
                    <FaHandHolding className={cs.handIcon} />
                </div>
                <p className="text-light font-weight-bold font-size-lg mt-15 mb-0">Total Order Places</p>
                <div className="text-white font-weight-bold display-3 mt-1"> 200 </div>
            </div>
            </a>
      </div>
    </>
  );
}
