/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export const SavedSearchtile = () => {
  return (
    <>
      <div className={`card card-custom gutter-b`}  >
          <div className="card-body savedSearchTile">
              <span className="small_title">SAVED SEARCH</span>
          </div>
      </div>
    </>
  );
}