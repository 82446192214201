/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
import { GiCutDiamond } from "react-icons/gi";

export const DiamondRequestTile = () => {
  return (
    <>
      <div className={`card card-custom gutter-b ${cs.diamondRequestTile} ${cs.dashTileHeight}`}  >
        <a  href="#" >
            <div className="card-body">
                <div className={cs.bgIcon}>
                    <GiCutDiamond className={cs.diamondIcon} />
                </div>
                <p className="text-light font-weight-bold font-size-lg mt-15 mb-0">Diamond Requested</p>
                <div className="text-white font-weight-bold display-3 mt-1"> 15 </div>
            </div>
        </a>
    </div>
    </>
  );
}
