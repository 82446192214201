import React, { useMemo ,useState,useEffect} from "react";
import { GetDiamondDB,StoreDiamondDB } from "../../_helpers/IndexedDB";
import { GridCardContent } from "./../grid/card/gridCardContent";
import { useLocation } from "react-router-dom";
import {Modal,Button} from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import {toAbsoluteUrl} from "../../_helpers";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import axios from "axios";
import {IUD_SHORTLIST} from "./../../../env_config"
import { BsArrowLeftShort } from "react-icons/bs";
import VideoIframe from "../grid/card/VideoIframe";
export const ProductDetailMain = () => {
    const [diamondsearch,setDiamondSearch] = useState([])
    const location = useLocation();
    
    const [data,setData] = useState(location.state)
    useEffect(() => {
       // console.log("Nice")
        GetDiamondDB().then(response => {
            setDiamondSearch(response)
            const propobject = response.find(obj => obj.ProductId === location.state.ProductId);
            setData(propobject)
            setUpdate(propobject.Shortlisted)
        })
    },[location.state])
    
    const [showWithoutModel, setShowWithoutModel] = React.useState(false);
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const id = useMemo(()=>localStorage.getItem("C_id"),[])
    const userCurrency = useMemo(()=>localStorage.getItem("userCurrency"),[])
    const usercur = JSON.parse(userCurrency)
    let ans ;
    if (diamondsearch !== undefined) {
            const cxProduct = data;
             ans = diamondsearch.filter((ele) => {
                if (ele.C_Weight>=cxProduct.C_Weight-0.1 && ele.C_Weight<=cxProduct.C_Weight+0.1 ) 
                    {
                        if (ele.ProductId !== cxProduct.ProductId &&
                        ele.C_Shape === cxProduct.C_Shape &&
                        ele.C_Color === cxProduct.C_Color &&
                        ele.C_Cut === cxProduct.C_Cut &&
                        (ele.ClarityCalc>=cxProduct.ClarityCalc-1 && ele.ClarityCalc<=cxProduct.ClarityCalc+1))
                        {
                            ele.absPrice = Math.abs(cxProduct.OurPrice - ele.OurPrice)
                            return ele;
                        }
                    }
                })
            function sortByProperty(property) {
                return function (a, b) {
                    if (a[property] > b[property])
                        return 1;
                    else if (a[property] < b[property])
                        return -1;

                    return 0;
                }
            }
            ans.sort(sortByProperty("absPrice"))
        }
    const [update,setUpdate] = React.useState(0);
    // console.log("data.Shortlisted:",data.Shortlisted,"update:",update)
      // -------------------------------------------------

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------

    
    function handleClose(event, reason) {
        setSuccessOpen(false)
        setOpen(false);
        setShowVideo(false);
        setShowWithoutModel(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

  
    const openVideoModal = () => setShowVideo(true);
    
    function openHeartModal() {
        setShowHeartImg(true)
    }
    function openAssetModal() {
        setShowAssetImg(true)
    }
    function openArrowModal() {
        setShowArrowImg(true)
    }
    function withoutSession() {
        setOpen(true);
    }
    function openModal() {
        setShowWithoutModel(true);
    }

    function handleShortlist(props) {
       // console.log(props,"propsprops")
        const newdiamondata = diamondsearch.map(value => {
            if(value.ProductId === props.ProductId){
                value.Shortlisted = 1
            }
            return value
        })
        // console.log(newdiamondata,"newdiamondata")
        StoreDiamondDB(newdiamondata)
        const values = JSON.stringify({
            Tag: "I",
            Id: 0,
            CustomerId: user.CustomerId,
            ConsumerId: id,
            ProductId: props.ProductId,
            CertiNo: props.Certi_No,
            WLDiamondType: `${props.WLDiamondType}`,
            Currency: user.Currency,
            CurrencyConversionRate: usercur[0].ConversionRate,
            MarkUpNatural: user.MarkUpNatural,
            MarkUpNaturalFancy: user.MarkUpNaturalFancy,
            MarkUpLab: user.MarkUpLab,
            MarkUpLabFancy: user.MarkUpLabFancy,
            OurRate: props.OurRate,
            OurPrice: props.OurPrice,
            MarkUpRate: props.MarkUpRate,
            MarkUpPrice: props.MarkUpPrice,
            CreatedAt: "0000-00-00 00:00:00",
        });
        // console.log(values);
        axios({
            method: "POST",
            url: IUD_SHORTLIST,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            data: values,
        })
            .then(function(res) {
                // console.log(res.data);
                // alert("Data added successfully");
                setUpdate(1)
                setSuccessOpen(true);
                setShowWithoutModel(false);
            })
            .catch(function(res) {
                console.log("error",values);
                // console.log(res);
            });
    }

    function Currency(){
        if (user.Currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (user.Currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (user.Currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (user.Currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (user.Currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (user.Currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }
    }
    // const backBtn = () =>{
    //     history.push({  pathname: '/grid'})
    // }

    function certificateLink(){
        if (data.Lab === "IGI"){
            const  certificate_link = `https://www.igi.org/viewpdf.php?r=${data.Certi_No}`
            return certificate_link
        } else if (data.Lab === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${data.Certi_No}`
            return certificate_link
        } else if (data.Lab === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_No}`
            return certificate_link
        } else if (data.Lab === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_No}`
            return certificate_link
        } else if (data.Certificate_link){
            const certificate_link = data.Certificate_link
            return certificate_link
        }
    }

    function availabilityIcon(){
        if (data.Availability === 'Guaranteed'){
            const availabilty_icon= <span className="font-weight-bolder label label-success">G</span>
           	return  availabilty_icon
        }else if(data.Availability === 'Memo'){
            const availabilty_icon= <span className="font-weight-bolder label label-primary">M</span>
           	return  availabilty_icon
        }else if(data.Availability === 'Hold'){
            const availabilty_icon= <span className="font-weight-bolder label label-primary">H</span>
           	return  availabilty_icon
        }else if(data.Availability === 'Not Available'){
            const availabilty_icon= <span className="font-weight-bolder label label-danger">NA</span>
           	return  availabilty_icon
        }else if(data.Availability === 'Subject to Prior sale'){
            const availabilty_icon= <span className="font-weight-bolder label label-warning">S</span>
            return  availabilty_icon
        }else{
            const availabilty_icon= <span className="font-weight-bolder label label-white">-</span>
            return  availabilty_icon
        }
    }
    return (
        <>
            <div className={`container product_detail ${
                    data.WLDiamondType === "N" ? "natTab" : "labTab"
                }`}>
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-body">
                        <div className="row">
                            {/* data: {JSON.stringify(data)} */}
                            <div className="col-12">
                                <span className="cursor-pointer text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> go to result</span>
                            </div>
                            <div className="col-md-6 d-flex">
                                <ul className="img_thumb_box mt-4 ml-5 pl-0">
                                    <li className="img_thumb">
                                        {data.AWS_Image ? (
                                            <img
                                                className="img thumb"
                                                alt={data.C_Shape}
                                                src={data.AWS_Image}
                                            />
                                        ) : (
                                            <img
                                                className="img thumb"
                                                alt="shape"
                                                
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${data.C_Shape}-no.png`
                                                )}
                                            />
                                        )}
                                    </li>
                                    {data.VideoLink =="1"  ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                                            {/* <FaVideo className="font-size-sm text-primary cursor-pointer"  /> */}
                                            {/* <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/heart-no.png`)}/> */}
                                        </li>
                                    ):('')}
                                    {data.AWS_Heart ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                                           
                                        </li>
                                        
                                    ):('')}
                                    {data.AWS_Arrow ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                                            
                                        </li>
                                    ):('')}
                                    {data.AWS_Asset ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                                            
                                        </li>
                                    ):('')}
                                </ul>
                                <div className="img_box mt-4">
                                    {data.AWS_Image ? (
                                        <img
                                            className="img-fluid"
                                            alt={data.C_Shape}
                                            src={data.AWS_Image}
                                        />
                                    ) : (
                                        <img
                                            className="img-fluid"
                                            alt="shape"
                                            src={toAbsoluteUrl(
                                                `/media/shape/${data.C_Shape}-no.png`
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12 my-3 d-flex justify-content-lg-start align-items-center">
                                        <span className="font-size-sm text-primary mr-4">
                                            <a
                                                id={data.Certi_No}
                                                href={certificateLink()}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="font-size-sm text-primary"
                                            >
                                                {data.Lab}-{data.Certi_No}
                                            </a>
                                        </span>
                                        <div className="font-size-sm mr-1 text-dark-50">Stock No: <div className="font-size-sm text-dark-50 font-weight-bold d-inline-block">{data.WLDiamondType === "N" ? "N" :"L"}-{data.ProductId ? data.ProductId : "-"}</div></div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <span className="text-dark font-weight-bold text-capitalize font-size-h2">
                                        <span className="pr-2">{data.Lab}</span>
                                            <span className="pr-2">
                                                {data.C_Weight.toFixed(2)}
                                            </span>
                                            <span className="pr-2">{data.C_Shape}</span>
                                            { (data.F_Color) ?
                                                (
                                                    <>
                                                        <span className="pr-2">{data.F_Intensity}</span>
                                                        <span className="pr-2">{data.F_Overtone}</span>
                                                        <span className="pr-2">{data.F_Color}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="pr-2">{data.C_Color}</span>
                                                    </>
                                                )
                                            }
                                            <span className="pr-2">{data.C_Clarity}</span>
                                            <span className="pr-2">{data.C_Cut}</span>
                                            <span className="pr-2">{data.C_Polish}</span>
                                            <span className="pr-2">{data.C_Symmetry}</span>
                                            <span className="pr-2">{data.C_Fluorescence}</span>
                                        </span>
                                    </div>
                                    <div className="col-12 d-flex align-items-end mt-5">
                                        <span>
                                            <div className="font-size-sm text-dark-50">{Currency()}/CT {data.MarkUpRate.toLocaleString()}</div>
                                            <div className="font-size-h1 font-weight-bold text-primary">{Currency()}{data.MarkUpPrice.toLocaleString()}</div>
                                        </span>
                                    </div>
                                    <div className="col-12 mt-5">
                                        {id === "-1" || id=== "-2" || id === "" ? (
                                            <>
                                                <button
                                                    onClick={() => withoutSession()}
                                                    className={`btn font-weight-bold ${update === 1 ? "btn-info" : "btn-danger"}`}
                                                >
                                                    {update === 1 ? "Shortlisted" : "Shortlist"}
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                 <button
                                                    key={data.ProductId}
                                                    onClick={() => openModal(data)}
                                                    className={`btn font-weight-bold ${update === 1 ? "btn-info" : "btn-primary"}`}
                                                >
                                                    {update === 1 ? "Shortlisted" : "Shortlist"}
                                                </button>
                                                <Modal
                                                    show={showWithoutModel}
                                                    onHide={handleClose}
                                                    backdrop="static"
                                                    keyboard={false}
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>{update === 1 ? "Warning !": " Confirmation"}</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p className="font-weight-bold">
                                                            {update === 1 ? "": " Are you sure want to Shortlist ?"}
                                                            { (update === 1) ?
                                                                (
                                                                    <>
                                                                        This stone is already shortlisted
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                                                                            <span className="pr-2">
                                                                                {data.C_Weight.toFixed(2)}
                                                                            </span>
                                                                            <span className="pr-2">{data.C_Shape}</span>
                                                                            <span className="pr-2">{data.C_Color}</span>
                                                                            <span className="pr-2">{data.C_Clarity}</span>
                                                                            <span className="pr-2">{data.C_Cut}</span>
                                                                            <span className="pr-2">{data.C_Polish}</span>
                                                                            <span className="pr-2">{data.C_Symmetry}</span>
                                                                            <span className="pr-2">{data.C_Fluorescence}</span>
                                                                        </span> 
                                                                    </>
                                                                )
                                                            }
                                                        </p>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button
                                                            variant="secondary"
                                                            onClick={handleClose}
                                                        >
                                                            {update === 1 ? "Ok": "No"}
                                                        </Button>
                                                        { (update === 1) ?
                                                            (
                                                                <> </>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() =>
                                                                            handleShortlist(data)
                                                                        }
                                                                    >
                                                                        Yes
                                                                    </Button>
                                                                </>
                                                            )
                                                        }
                                                    </Modal.Footer>
                                                </Modal>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-12 mt-5">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">M</div>
                                            <div className="text-primary ml-2">  {data.C_Length} x {data.C_Width} x {data.C_Depth}  </div>
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">T</div>
                                            <div className="text-primary ml-2">  {data.C_TableP ? <>{data.C_TableP}%</> : "-"}  </div>
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">D</div>
                                            <div className="text-primary ml-2">  {data.C_DefthP ? <>{data.C_DefthP}%</> : "-"}  </div>
                                        </span>
                                    </div>
                                    {data.Ratio ? (
                                        <div className="col-12">
                                            <span className="d-flex align-items-center font-weight-bold">
                                                <div className="text-dark mr-1">R</div>
                                                <div className="text-primary ml-2">  {data.Ratio ? <>{data.Ratio}</> : "-"}  </div>
                                            </span>
                                        </div>
                                    ):('')}
                                    <div className="col-12">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <span className="text-dark mr-1"><FaCalendarAlt className="text-danger mr-2" /></span> Estimate Shipping Days:
                                            <span className="text-primary ml-2">{data.ShippingDays ? data.ShippingDays : "-"} days</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-header justify-content-center mb-3">
                        <div className="card-title m-0">
                            <span className="card-label text-dark">
                                DESCRIPTION
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {/* data: {JSON.stringify(data)} */}
                            <div className="col-md-6">
                                <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> Diamond Details </div>
                                {/* <div className="row mb-1">
                                    <div className="col-6"> Availability </div>
                                    <div className="col-6 font-weight-bolder"> {data.Availability ? data.Availability : "-"} </div>
                                </div> */}
                                <div className="row mb-1">
                                    <div className="col-6"> Stock ID </div>
                                    <div className="col-6 font-weight-bolder"> {data.WLDiamondType === "N" ? "N" :"L"}-{data.ProductId ? data.ProductId : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> Shape </div>
                                    <div className="col-6 font-weight-bolder">  {data.C_Shape ? data.C_Shape : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> Carat </div>
                                    <div className="col-6 font-weight-bolder">  {data.C_Weight ? data.C_Weight : "-"} </div>
                                </div>
                                { (data.C_Color !=="fancy") ? (
                                    <div className="row mb-1">
                                        <div className="col-6"> Color </div>
                                        <div className="col-6 font-weight-bolder">  {data.C_Color ? data.C_Color : "-"} </div>
                                    </div>
                                ):('')}
                               
                                <div className="row mb-1">
                                    <div className="col-6"> Clarity </div>
                                    <div className="col-6 font-weight-bolder">  {data.C_Clarity ? data.C_Clarity : "-"} </div>
                                </div>
                                { (data.C_Cut) ? (
                                    <div className="row mb-1">
                                        <div className="col-6"> Cut </div>
                                        <div className="col-6 font-weight-bolder">  {data.C_Cut ? data.C_Cut : "-"} </div>
                                    </div>
                                ):('')}
                                <div className="row mb-1">
                                    <div className="col-6"> Polish </div>
                                    <div className="col-6 font-weight-bolder">  {data.C_Polish ? data.C_Polish : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> Symmetry </div>
                                    <div className="col-6 font-weight-bolder">  {data.C_Symmetry ? data.C_Symmetry : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> Fluorescence</div>
                                    <div className="col-6 font-weight-bolder">  {data.C_Fluorescence ? data.C_Fluorescence : "-"} </div>
                                </div>
                                {/* <div className="row mb-1">
                                    <div className="col-6"> Fluorescence Intensity </div>
                                    <div className="col-6 font-weight-bolder">  {data.F_Intensity ? data.F_Intensity : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> Fluorescence Color </div>
                                    <div className="col-6 font-weight-bolder">  {data.F_Color ? data.F_Color : "-"} </div>
                                </div> */}
                                <div className="row mb-1">
                                    <div className="col-6">Lab</div>
                                    <div className="col-6 font-weight-bolder">  {data.Lab ? data.Lab : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Certificate No.</div>
                                    <div className="col-6 font-weight-bolder">
                                        <a
                                            id={data.Certi_No}
                                            href={certificateLink()}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="font-size-sm text-primary"
                                        >
                                            {data.Certi_No}
                                        </a>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Location</div>
                                    <div className="col-6 font-weight-bolder">  {data.Country ? data.Country : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Shipping Days</div>
                                    <div className="col-6 font-weight-bolder">  {data.ShippingDays ? <>{data.ShippingDays} Days</> : "-"} </div>
                                </div>
                            </div>
                            <div className="col-md-6"> 
                                { (data.C_Color ==="fancy") ? (
                                    <>
                                        <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary">Fancy Color Details </div>
                                        <div className="row mb-1">
                                            <div className="col-6">Intensity</div>
                                            <div className="col-6 font-weight-bolder">  {data.F_Intensity ? data.F_Intensity : "-"} </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-6">Overtone</div>
                                            <div className="col-6 font-weight-bolder">  {data.F_Overtone ? data.F_Overtone : "-"} </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-6">Color</div>
                                            <div className="col-6 font-weight-bolder">  {data.F_Color ? data.F_Color : "-"} </div>
                                        </div>
                                    </>
                                ):('')}
                               
                                <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> Parameter Details </div>
                                <div className="row mb-1">
                                    <div className="col-6">Measurement</div>
                                    <div className="col-6 font-weight-bolder">  {data.C_Length} x {data.C_Width} x {data.C_Depth}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Table %</div>
                                    <div className="col-6 font-weight-bolder">  {data.C_TableP ? `${data.C_TableP}%` : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Depth %</div>
                                    <div className="col-6 font-weight-bolder">  {data.C_DefthP ? `${data.C_DefthP}%` : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Crown</div>
                                    <div className="col-6 font-weight-bolder">  {data.Crn_Ht ? `${data.Crn_Ht}%` : "-"}, {data.Crn_Ag ? `${data.Crn_Ag}°` : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Pavilion</div>
                                    <div className="col-6 font-weight-bolder">  {data.Pav_Dp ? `${data.Pav_Dp}%` : "-"}, {data.Pav_Ag ? `${data.Pav_Ag}°` : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Girdle</div>
                                    <div className="col-6 font-weight-bolder">   {data.Gridle ? data.Gridle : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Girdle Thick</div>
                                    <div className="col-6 font-weight-bolder">   {data.Girdle_Thick ? data.Girdle_Thick : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Girdle Thin</div>
                                    <div className="col-6 font-weight-bolder">   {data.Girdle_Thin ? data.Girdle_Thin : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">Culet</div>
                                    <div className="col-6 font-weight-bolder">   {data.Cutlet ? data.Cutlet : "-"}  </div>
                                </div>
                                {data.WLDiamondType === "L" ? 
                                    <>
                                        { (data.GrowthType) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> Growth Type </div>
                                                <div className="col-6 font-weight-bolder">  {data.GrowthType ? data.GrowthType : "-"} </div>
                                            </div>
                                        ):('')}
                                        { (data.Treatment) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> Treatment </div>
                                                <div className="col-6 font-weight-bolder">  {data.Treatment ? data.Treatment : "-"} </div>
                                            </div>
                                        ):('')}
                                    </>
                                :   
                                    <>
                                        { (data.Origin) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> Origin </div>
                                                <div className="col-6 font-weight-bolder">  {data.Origin ? data.Origin : "-"} </div>
                                            </div>
                                        ):('')}
                                        { (data.Brand) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> Brand </div>
                                                <div className="col-6 font-weight-bolder">  {data.Brand ? data.Brand : "-"} </div>
                                            </div>
                                        ):('')}
                                         { (data.Treatment) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> Treatment </div>
                                                <div className="col-6 font-weight-bolder">  {data.Treatment ? data.Treatment : "-"} </div>
                                            </div>
                                        ):('')}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-header justify-content-center mb-3">
                        <div className="card-title m-0">
                            <span className="card-label text-dark">
                                SIMILAR DIAMONDS
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row m-0">
                            {ans ?  
                                ans.slice(0, 4).map((val) => {
                                    // console.log(val)
                                    return (
                                        <GridCardContent
                                            key={val.ProductId}
                                            {...val}
                                        />
                                    );
                                })
                             : <div className="col-12 text-center">
                                <p className="font-size-h5"> No records found</p>
                             </div> }
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <MySnackbarContentWrapper
                        onClose={handleClose}
                        variant="error"
                        message="You can not shortlist stone without session"
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={successOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <MySnackbarContentWrapper
                        onClose={handleClose}
                        variant="success"
                        message="Your stone is shortlisted"
                    />
                </Snackbar>
                {/* <Modal
                    show={showVideo}
                    onHide={handleClose}
                    size="xl"
                >
                    <Modal.Body className="text-center">
                        <iframe title="Video" src={data.WLDiamondType === "N" ? `https://pro360video.com/video.php?refno=${data.Certi_No}` : `https://pro360video.com/labgrown.php?refno=${data.Certi_No}` } height='600px' width='700px' frameBorder='0'></iframe>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal> */}
                <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={data.WLDiamondType === "N" ? `https://pro360video.com/video.php?refno=${data.Certi_No}` : `https://pro360video.com/labgrown.php?refno=${data.Certi_No}` }
                />
                <Modal
                    show={showHeartImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Heart} alt={data.C_Shape} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showArrowImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Arrow} alt={data.C_Shape} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showAssetImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Asset} alt={data.C_Shape} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};
