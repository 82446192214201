import React, {  useState,useMemo } from "react";
import {
    Modal,
    Button,
} from "react-bootstrap";
import axios from "axios";
import {IUD_SHORTLIST} from "./../../../env_config"
import { GetDiamondDB ,StoreDiamondDB} from "../../_helpers/IndexedDB";
import { useEffect } from "react";
export const PopUp = (props) => {
    const [update,setUpdate] = React.useState(props.Shortlisted);
    const [show, setShow] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const id = useMemo(()=>localStorage.getItem("C_id"),[])
    const userCurrency = useMemo(()=>localStorage.getItem("userCurrency"),[])
    const usercur = JSON.parse(userCurrency)
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [diamondsearch,setDiamondSearch] = useState([])
    useEffect(() => {
        GetDiamondDB().then(response => {
            setDiamondSearch(response)
        })
    },[diamondsearch])
    function handleShortlist(props) {
        // console.log(props.CertificateNo)
        // console.log(props,"propsprops")
        const newdiamondata = diamondsearch.map(value => {
            if(value.ProductId === props.ProductId){
                value.Shortlisted = 1
            }
            return value
        })
        // console.log(newdiamondata,"newdiamondata")
        StoreDiamondDB(newdiamondata)
        const values = JSON.stringify({
            Tag: "I",
            Id: 0,
            CustomerId: user.CustomerId,
            ConsumerId: id,
            ProductId: props.ProductId,
            CertiNo: props.Certi_No,
            WLDiamondType: `${props.WLDiamondType}`,
            Currency: user.Currency,
            CurrencyConversionRate: usercur[0].ConversionRate,
            MarkUpNatural: user.MarkUpNatural,
            MarkUpNaturalFancy: user.MarkUpNaturalFancy,
            MarkUpLab: user.MarkUpLab,
            MarkUpLabFancy: user.MarkUpLabFancy,
            OurRate: props.OurRate,
            OurPrice: props.OurPrice,
            MarkUpRate: props.MarkUpRate,
            MarkUpPrice: props.MarkUpPrice,
            CreatedAt: "0000-00-00 00:00:00",
        });
        // console.log(values);
        axios({
            method: "POST",
            url: IUD_SHORTLIST,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            data: values,
        })
            .then(function(res) {
                // console.log(res.data);
                // console.log("success",values);
                alert("Stone added successfully");
                setUpdate(1)
                setSuccessOpen(true);
                setShow(false)
            })
            .catch(function(res) {
                console.log("error",values);
                // console.log(res);
            });
    }

    return (
        <>
            <button
                onClick={() => handleShow()}
                className={`btn font-weight-bold ${update === 1 ? "btn-info" : "btn-primary"}`}
            >
                {update === 1 ? "Shortlisted" : "Shortlist"}
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{update === 1 ? "Warning !": " Confirmation"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="font-weight-bold">
                        {update === 1 ? "": " Are you sure want to Shortlist ?"}
                        { (update === 1) ?
                            (
                                <>
                                    This stone is already shortlisted
                                </>
                            ) : (
                                <>
                                    <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                                        <span className="pr-2">
                                            {props.C_Weight.toFixed(2)}
                                        </span>
                                        <span className="pr-2">{props.C_Shape}</span>
                                        <span className="pr-2">{props.C_Color}</span>
                                        <span className="pr-2">{props.C_Clarity}</span>
                                        <span className="pr-2">{props.C_Cut}</span>
                                        <span className="pr-2">{props.C_Polish}</span>
                                        <span className="pr-2">{props.C_Symmetry}</span>
                                        <span className="pr-2">{props.C_Fluorescence}</span>
                                    </span>
                                </>
                            )
                        }
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        {update === 1 ? "Ok": "No"}
                    </Button>
                    { (update === 1) ?
                        (
                            <> </>
                        ) : (
                            <>
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        handleShortlist(props)
                                    }
                                >
                                    Yes
                                </Button>
                            </>
                        )
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};
