/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../_helpers";
import{FaHeart,FaCalendarAlt,FaUserCircle} from "react-icons/fa";

export const RecentDiamondCard = (props) => {
    return (
        <>
            <div className="col-md-3">
                <div className="row">
                    <div className="col-12">
                        <img className="img-fluid bg-gray-200" alt="shape" src={toAbsoluteUrl(`/media/shape/${props.shape}-no.png`)}/>
                    </div>
                    <div className="col-12 my-3 d-flex justify-content-lg-between align-items-center">
                        <span className={`${props.stock_status}_label mr-1`}>{props.stock_status}</span>
                        <OverlayTrigger placement="left" className="text-capitalize" overlay={<Tooltip id="tooltip" >{props.location}</Tooltip>} >
                            <img className="img-fluid flagIco mr-2" alt="profile" src={toAbsoluteUrl(`/media/map/${props.location}.png`)}/>
                        </OverlayTrigger>
                        <span className="font-size-xs text-primary">{props.certi}</span>
                        <a href="#" className=""><FaHeart /></a>
                    </div>
                    <div className="col-12">
                        <span className="text-dark font-weight-bold text-capitalize">{props.description}</span>
                    </div>
                    <div className="col-12">
                        <span className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">3EX</span>
                        <span className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1">EC</span>
                        <span className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1">LUSTER</span>
                        <span className="label label-outline-warning label-pill label-inline bg-warning-o-20">NO SHADE</span>
                    </div>
                    <div className="col-12 mt-2">
                        <span className="d-flex align-items-center font-size-sm font-weight-bold"> <span className="text-dark-25 mr-1"><FaCalendarAlt /></span> Delivery In: <span className="text-primary font-size-sm mr-1">1-2 days</span></span>
                    </div>
                    <div className="col-12">
                        <span className="text-dark-25 mr-1"><FaUserCircle /></span> <span className="font-size-sm mr-1">Supplier: <span className="text-primary font-size-sm font-weight-bold">{props.suplier}</span></span>
                    </div>
                    <hr className="w-100 mx-5 my-2"/>
                    <div className="col-12 d-flex align-items-end">
                        <span>
                            <div className="font-size-xs text-dark-25">{props.dollarprice}</div>
                            <div className="font-size-h1 font-weight-bold text-primary">{props.Total}</div>
                        </span>
                        <span className="text-danger ml-3 mb-2 small">{props.discount}</span>
                    </div>
                    <hr className="w-100 mx-5 my-2"/>
                    <div className="col-12 mt-2 text-center">
                        <a href="#" className="btn btn-light-primary font-weight-bold">Remove</a>
                    </div>
                </div>
            </div>   
        </>
    )
}