/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
import {toAbsoluteUrl} from "../../../_helpers";

export const IncompleteProfileTile = () => {
  return (
    <>
      <div className={`card card-custom gutter-b ${cs.incompleteProfile} ${cs.dashTileHeight}`}  >
          <div className="card-body">
            <div className="row">
                <div className="col-8">
                    <h3 className="font-weight-bolder text-dark">Your profile is incomplete.</h3>
                    <p>
                        Let’s Finish setting up your profile. It’s best to complete your and enjoy uninterrupted services. 
                    </p>
                    <a href="#" className="btn btn-danger font-weight-bold btn-square">Complete Profile</a>
                </div>
                <div className="col-4 d-flex align-items-center">
                    <img className="img-fluid opacity-50" alt="profile" src={toAbsoluteUrl("/media/svg/illustrations/data-points.svg")}/>
                </div>
            </div>
          </div>
      </div>
    </>
  );
}
