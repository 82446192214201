/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { Basic } from "./searchcomponent/Basic";
import { StoreDiamondDB } from "../../_helpers/IndexedDB";
import {
    Modal,
    Button,
} from "react-bootstrap";
import { BasicLab } from "./searchcomponent/BasicLab";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core/";
import PropTypes from "prop-types";
import { Preference1 } from "./searchcomponent/Preference1";
import axios from "axios";
import {SEARCH_LOG,SEARCH_COUNT,FETCH_DIAMOND} from "./../../../env_config"
// import { decompress } from "compress-json";
import { useContext } from "react";
import  DiamondContext  from "./SearchDiamondContext";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
    Backdrop,
    CircularProgress
} from "@material-ui/core";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
// import { PrevSesModel } from "./../selectSession/PrevSesModel"
// import { NewSesModel } from "./../selectSession/NewSesModel"
// import { WithoutSession } from "./../selectSession/WithoutSession"
import { SelectSessionModal } from "../selectSession/selectSession"

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const SearchDiamond = (items) => {
    const diamondsearch = useContext(DiamondContext)
    
    // console.log(diamondsearch,"diamondsearch")
    const [sessModalShow, setSessModalShow] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openLoader, setOpenLoader] = React.useState(false);
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [zeroSuccessOpen, setZeroSuccessOpen] = React.useState(false);
    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    function handleClose(event, reason) {
        setOpen(false);
        setSessModalShow(false);
        setZeroSuccessOpen(false);
    }

    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(!openLoader);
    };

    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
    };

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };


    const history = useHistory();
    // const { user } = useSelector((state) => state.auth);

    const [user, setUser] = React.useState([]);

    React.useEffect(() => {
        const userData = localStorage.getItem("userData");
        setUser(JSON.parse(userData))
    },[])

    const [value, setValue] = React.useState(window.glob||0);
    // const [id, setID] = React.useState([]);
    const [natData, setNatData] = React.useState([]);
    const [stoneCount, setStoneCount] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const id = React.useMemo(()=>localStorage.getItem("C_id"),[])
    // React.useEffect(() => {
    //     const cid = localStorage.getItem("C_id");
    //     setID(cid);
    // }, []);

    // const handleClose = () => setSessModalShow(false);
    // const handleShow = () => setSessModalShow(true);
    function handelSession(){
        setSessModalShow(true)
    }
    
    React.useEffect(() =>{
        if (id === "-2" ){
            setTimeout(() => {
                handelSession()
        }, 500);
        }
   }, [id]);

  

    function handleChange(event, newValue) {
        window.glob = newValue
        setValue(newValue);
    }
    function handelStoneCountError(){
        setOpen(true)  
        disableLoading() 
    }
    function handelStoneCountWarn(){
        setZeroSuccessOpen(true)  
        disableLoading() 
    }
    function handelRest(newValue){
        setTimeout(() => {
            window.location.reload(true)
            window.glob = newValue
        }, 500);
    }
   
    // natural ---------------------------------------------
    function handleNaturalData(values) {
        window.Nat = values
        let len = Object.keys(values).length;
        const temp = values;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": ${user.CustomerId}, "ConsumerId":${id=== "" ? "-1" : id}, "WLDiamondType": "N" }`;
        axios({
            method: "POST",
            url: SEARCH_COUNT,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
        .then(function(res) {
            setStoneCount(JSON.stringify(res.data.StoneCount));
            if(res.data.Status == 2 || res.data.Status == null){
                localStorage.clear();
                window.location.reload(true);
            }
            
            if (JSON.stringify(res.data.StoneCount) === "0"){
                setZeroSuccessOpen(true)  
                disableLoading() 
            } else{
                setZeroSuccessOpen(false)
                if (res.data.StoneCount > 5000 ){
                    handelStoneCountError()
                } else{
                    axios({
                        method: "POST",
                        url: FETCH_DIAMOND,
                        // url:"https://ccmodenode.adiamorvideo.com/WLSPCompressedDiamondData",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: finalData,
                    })
                    .then(function(res) {
                        setNatData([...data, ...res.data.ProductDetails]);
                        // console.log(res.data.ProductDetails,"WWWWWWW")
                        StoreDiamondDB([...res.data.ProductDetails])
                        diamondsearch.updateState()
                        // setNatData([...data, decompress(...res.data.ProductDetails)]);
                        disableLoading();
                        history.push({
                            pathname: "/grid",
                            state: res.data.ProductDetails,
                            // state: decompress(res.data.ProductDetails),
                        });
                    })
                    .catch(function(res) {
                        console.error(res);
                    });
                }
            }
            res.data.StoneCount === null ? handelStoneCountWarn() : console.log("");
            (res.data.StoneCount < 5000 && res.data.StoneCount > "0") ? setSuccessOpen(true) : setSuccessOpen(false);
        })
        .catch(function(res) {
            disableLoading();
        });
        axios({
            method: "POST",
            url: SEARCH_LOG,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
        .then(function(res) {
        })
        .catch(function(res) {
             console.error(res);
        });
       
        setFinalFetchData(finalData);
    }
    // lab -------------------------------------------------

    function handleLabData(values) {
        window.Lab = values
        let len = Object.keys(values).length;
        const temp = values;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": ${user.CustomerId}, "ConsumerId":${id=== "" ? "-1" : id}, "WLDiamondType": "L" }`;
        axios({
            method: "POST",
            url: SEARCH_COUNT,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then(function(res) {
                setStoneCount(JSON.stringify(res.data.StoneCount));
                if(res.data.Status == 2 || res.data.Status == null){
                    localStorage.clear();
                    window.location.reload(true);
                }

                if (JSON.stringify(res.data.StoneCount) === "0"){
                    setZeroSuccessOpen(true)  
                    disableLoading() 
                } else{
                    setZeroSuccessOpen(false)
                    if (res.data.StoneCount > 5000 ){
                        handelStoneCountError()
                    } else{
                        axios({
                            method: "POST",
                            url: FETCH_DIAMOND,
                            // url:"https://ccmodenode.adiamorvideo.com/WLSPCompressedDiamondData",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: finalData,
                        })
                        .then(function(res) {
                            setNatData([...data, ...res.data.ProductDetails]);
                            // setNatData([...data, decompress(...res.data.ProductDetails)]);
                            // console.log(res.data.ProductDetails,"WWWWWWW")
                            StoreDiamondDB([...res.data.ProductDetails])
                            diamondsearch.updateState()
                            disableLoading();
                            history.push({
                                pathname: "/grid",
                                state: res.data.ProductDetails,
                                // state: decompress(res.data.ProductDetails),
                            });
                        })
                        .catch(function(res) {
                            console.error(res);
                        });
                    }
                }
                res.data.StoneCount === null ? handelStoneCountWarn() : console.log("");
                (res.data.StoneCount < 5000 && res.data.StoneCount > "0") ? setSuccessOpen(true) : setSuccessOpen(false);
            })
            .catch(function(res) {
                console.log(res);
                disableLoading();
            });
        axios({
            method: "POST",
            url: SEARCH_LOG,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then(function(res) {
                // console.log(res);
            })
            .catch(function(res) {
                console.log(res);
            });
        // disableLoading();
        // setSubmitting(false);
        setFinalFetchData(finalData);
    }

    const [finalFetchData, setFinalFetchData] = React.useState([]);
    React.useEffect(
        () => window.localStorage.setItem("fetchData", finalFetchData),
        [finalFetchData]
    );
    React.useEffect(
        () => window.localStorage.setItem("stoneCount", stoneCount),
        [stoneCount]
    );

    return (
    (user? 
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card card-custom card-sticky gutter-b">
                        <div
                            className="card-header minHeight justify-content-start p-0"
                            style={{
                                height: " 49px!important",
                            }}
                        >
                            <Paper position="static">
                                <Tabs value={value} onChange={handleChange}>
                                    {user.NaturalDiamondType==1 || user.NaturalFancyDiamondType==1 ? ( <Tab label="Natural" /> ) : ( '' )}
                                    {user.LabDiamondType==1 || user.LabFancyDiamondType==1  ? ( <Tab label="Lab Grown" /> ) : ( '' )}
                                </Tabs>
                            </Paper>
                        </div>
                        {user.NaturalDiamondType==1 || user.NaturalFancyDiamondType==1 ? (
                            <Formik
                                enableReinitialize = {true}
                                initialValues={window.Nat||{}}
                                onSubmit={async (values, { setSubmitting }) => {
                                    enableLoading();
                                    Object.keys(values).forEach(key => {
                                        if (values[key] === '' || values[key] === null || values[key].length === 0) {
                                                 delete values[key];
                                          }
                                        });
                                    setTimeout(() => {
                                        handleNaturalData(values);
                                    }, 1000);
                                }}
                            >
                                {(formik, isSubmitting, handleSubmit) => (
                                    <>
                                        <Form>
                                            {value === 0 && (
                                                <TabContainer>
                                                    <div className="card-body">
                                                        <div className="row natTab">
                                                            {/* data: {finalNaturalData} */}
                                                            <div className="col-12 mb-10">
                                                                <Basic formik={formik}/>
                                                            </div>
                                                            {/* <div className="col-12 mb-10">
                                                                <Preference />
                                                            </div> */}
                                                            <div className="col-12 mb-10">
                                                                <Preference1 />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card-footer natTab text-right border-0 sticky-top"
                                                        style={{ bottom: "0" }}
                                                    >
                                                        
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary px-10 font-weight-bold btn-square mr-2 btn-lg"
                                                            disabled={isSubmitting}
                                                            onClick={handleSubmit}
                                                        >
                                                            {isSubmitting
                                                                ? "Please wait..."
                                                                : "Search"}
                                                            {loading && (
                                                                <span className="ml-3 spinner spinner-white"></span>
                                                            )}
                                                        </button>
                                                        <Backdrop
                                                            sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                                                            open={openLoader}
                                                            // onClick={handleCloseLoader}
                                                        >
                                                            <CircularProgress color="secondary" />
                                                        </Backdrop>
                                                        <button
                                                            type="reset"
                                                            onClick={handelRest}
                                                            className="btn font-weight-bold btn-square btn-danger btn-lg px-10"
                                                        >
                                                            Reset
                                                        </button>
                                                       
                                                    </div>
                                                </TabContainer>
                                                
                                            )}
                                        </Form>
                                    </>
                                )}
                            </Formik>
                         ) : ( '' )}
                         {user.LabDiamondType==1 || user.LabFancyDiamondType==1 ? (
                            <Formik
                                enableReinitialize = {true}
                                initialValues={window.Lab||{}}
                                onSubmit={async (values, { setSubmitting }) => {
                                    enableLoading();
                                    Object.keys(values).forEach(key => {
                                        if (values[key] === '' || values[key] === null || values[key].length === 0) {
                                                 delete values[key];
                                          }
                                        });
                                    setTimeout(() => {
                                        handleLabData(values);
                                    }, 1000);
                                }}
                            >
                                {(formik, isSubmitting, handleSubmit) => (
                                    <>
                                        <Form>
                                            {value == ((user.NaturalDiamondType==0 && user.NaturalFancyDiamondType==0) ? 0 : 1) && (
                                                <TabContainer>
                                                    <div className="card-body">
                                                        <div className="row labTab">
                                                            <div className="col-12 mb-10">
                                                                <BasicLab formik={formik}/>
                                                            </div>
                                                            {/* <div className="col-12 mb-10">
                                                                <Preference />
                                                            </div> */}
                                                            <div className="col-12 mb-10">
                                                                <Preference1 />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card-footer labTab text-right border-0 sticky-top"
                                                        style={{ bottom: "0" }}
                                                    >
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary px-10 font-weight-bold btn-square mr-2 btn-lg"
                                                            disabled={isSubmitting}
                                                            onClick={handleSubmit}
                                                        >
                                                            {isSubmitting
                                                                ? "Please wait..."
                                                                : "Search"}
                                                            {loading && (
                                                                <span className="ml-3 spinner spinner-white"></span>
                                                            )}
                                                        </button>
                                                        <Backdrop
                                                            sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                                                            open={openLoader}
                                                            // onClick={handleCloseLoader}
                                                        >
                                                            <CircularProgress color="secondary" />
                                                        </Backdrop>
                                                        <button
                                                            type="reset"
                                                            onClick={handelRest}
                                                            className="btn font-weight-bold btn-square btn-danger btn-lg px-10"
                                                        >
                                                            Reset
                                                        </button>
                                                    </div>
                                                </TabContainer>
                                            )}
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        ) : ( '' )}
                    </div>
                </div>
                <div className="col-12 d-none"></div>
            </div>
            <Modal
                show={sessModalShow}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                // size="lg"
            >
                <Modal.Header className="selectSess" closeButton>
                    <Modal.Title>Start Session</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {/* <div className="row"> */}
                        {/* <div className="col-12 text-center my-5">
                            <PrevSesModel />
                        </div>
                        <div className="col-12 text-center my-5">
                            <NewSesModel setOpen={setOpen} setSessModalShow={setSessModalShow}/>
                        </div> */}
                        {/* <div className="col-12 text-center my-5"> */}
                            <SelectSessionModal setOpen={setOpen} setSessModalShow={setSessModalShow} />
                        {/* </div> */}
                    {/* </div> */}
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message={`${stoneCount} Stones found Please Narrow Your Search to 5000`}
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`${stoneCount} Stones found`}
                />
            </Snackbar>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={zeroSuccessOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="warning"
                    message={`0 Stones found please try again`}
                />
            </Snackbar>
        </>
    : "")
    );
};
