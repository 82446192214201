import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import styles from "./css/style.module.css"
import axios from "axios"
import {LOGIN} from "./../../../../env_config"

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [ipData, setIpdata] = React.useState([]);

  React.useEffect(() => {
  axios({
    method: 'GET',
    // url: "http://ip-api.com/json/?fields=status,country,region,regionName,city,district,zip,isp,mobile,proxy,hosting,query",
    url:"https://api.ipify.org",
    // headers: { 
    //     'Access-Control-Allow-Origin': '*', 
    //     'Content-Type': 'application/json'
    //   },
    })
    .then(function (res) {
        setIpdata({query:res.data})
    })
    .catch((res) => {
        console.log(res)
    });
  },[])
  
  const initialValues = {
    EMail: "",
    Password: "",
    JsonData: ipData
  };
  const LoginSchema = Yup.object().shape({
    EMail: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    Password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        axios({
          method: 'POST',
          url: LOGIN,
          headers: { 
              'Access-Control-Allow-Origin': '*', 
              'Content-Type': 'application/json'
            },
          data: values
        })
          .then(function (res) {
             disableLoading();
            const resData = JSON.stringify(res.data.Customer[0])
            const currencyData = JSON.stringify(res.data.Currency)
            const accessToken ="access-token-d2dff7b82f784de0aa10964abb20c1a5"
            props.login(accessToken);
            window.localStorage.setItem("userData", resData)
            window.localStorage.setItem("C_id", -1)
            // console.log(LOGIN)
            window.localStorage.setItem("userCurrency", currencyData)
          // console.log(res)
            setSubmitting(false);
          })
          .catch((res) => {
            disableLoading();
            setSubmitting(false);
            // console.log(res)
            window.localStorage.setItem("userData", '')
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div className={styles.web_login} id="kt_login_signin_form">
      <div className="container">
        {/* begin::Head */}
        <div className="text-center mb-8">
          <p className="text-muted font-weight-bold">
          </p>
        </div>
        {/* end::Head */}
        <div className={styles.login_form}>
          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            ) : (
              <div className="">
                {/* <div className="alert-text">
                  Use account <strong>admin@demo.com</strong> and password{" "}
                  <strong>demo</strong> to continue.
                </div> */}
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Email"
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
                    name="EMail"
                    autoComplete="off"
                    {...formik.getFieldProps("EMail")}
                  />
                  {formik.touched.EMail && formik.errors.EMail ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.EMail}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
                    name="Password"
                    autoComplete="off"
                    {...formik.getFieldProps("Password")}
                  />
                  {formik.touched.Password && formik.errors.Password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.Password}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="sum_tab text-center">
                        <button
                          type="submit"
                          className={`submit btn btn-success px-10 py-3 shadow-sm my-4`}
                          disabled={formik.isSubmitting} 
                        >
                          Login
                          {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </div>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
