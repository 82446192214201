import React, { useEffect , useState } from "react";
import { Field } from "formik";
import {toAbsoluteUrl} from "../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const Shape = () => {

    const ShapeData = ([
        {
            id: 1,
            shape:"round"
        },
        {
            id: 2,
            shape:"princess"
        },
        {
            id: 3,
            shape:"oval"
        },
        {
            id: 4,
            shape:"heart"
        },
        {
            id: 5,
            shape:"cushion"
        },
        {
            id: 6,
            shape:"emerald"
        },
        {
            id: 7,
            shape:"marquise"
        },
        {
            id: 8,
            shape:"cushion modified"
        },
        {
            id: 9,
            shape:"pear"
        },
        {
            id: 10,
            shape:"asscher"
        },
        {
            id: 11,
            shape:"radiant"
        },
        {
            id: 12,
            shape:"square radiant"
        },
        {
            id: 13,
            shape:"triangle"
        },
        {
            id: 14,
            shape:"trilliant"
        },
    ]);
    return (
        <>
        
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6">SHAPE</p>
                </div>
                <div className="col-lg-10">
                    {ShapeData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Shape" id={`${item.shape}_shape`} value={item.shape}/>
                            {/* <span></span> */}
                            <div className="shapeBox selectBox">
                                <SVG src={toAbsoluteUrl(`/media/shape/svg_shape/${item.shape}.svg`)} />
                                <br/>
                                <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip" >{item.shape}</Tooltip>} >
                                    <span className="text-uppercase text-truncate">{item.shape}</span>
                                </OverlayTrigger>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};
