import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Carat } from "./BasicLab/Carat";
import {Shape} from "./BasicLab/Shape"
import {Color} from "./BasicLab/Color"
import { Clarity } from "./BasicLab/Clarity";
import { Lab } from "./BasicLab/Lab";
// import { Shortcut } from "./BasicLab/Shortcut";
import { Finish } from "./BasicLab/Finish";
import { Flour } from "./BasicLab/Flour";
// import { Location } from "./BasicLab/Location";

export const BasicLab = ({formik}) => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        Basic
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <Shape />
                            <Carat formik={formik}/>
                            <Color formik={formik}/>
                            <Clarity />
                            <Lab />
                            {/* <Shortcut /> */}
                            <Finish />
                            <Flour />
                            {/* <Location /> */}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
