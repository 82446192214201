import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Crown } from "./Preference1/Crown";
// import { Girdle } from "./Preference1/Girdle";
// import { GirdleCondition } from "./Preference1/GirdleCondition";
import { Measurement } from "./Preference1/Measurement"
// import { Metrics } from "./Preference1/Metrics";
import { Pavallion } from "./Preference1/Pavallion";
import { Percentage } from "./Preference1/Percentage";
import { Price } from "./Preference/Price";

export const Preference1 = () => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        Preference
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <Price />
                           <Measurement />
                           <Percentage />
                           {/* <Metrics /> */}
                           {/* <Crown /> */}
                           {/* <Pavallion /> */}
                           {/* <Girdle /> */}
                           {/* <GirdleCondition /> */}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
