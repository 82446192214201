import React, { useEffect , useState, useMemo } from "react";
import objectPath from "object-path";
import {Link} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {toAbsoluteUrl} from "../../../../_helpers";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {HeaderMenu} from "./HeaderMenu";



export function HeaderMenuWrapper() {
    // const user = useSelector((state) => state.auth.user, shallowEqual);
    const [user, setUser] = React.useState([]);
    const [imageBroken, setImageBroken] = React.useState(false);
    React.useEffect(() => {
        const userData = localStorage.getItem("userData");
        setUser(JSON.parse(userData))
    },[])

    useEffect(() => {
      return () => {};
    }, [user]);
   

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(
                uiService.config,
                "header.menu.self.root-arrow"
            ),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false
        };
    }, [uiService]);
 

    return (<>
        {/*begin::Header Menu Wrapper*/}
        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            {layoutProps.disabledAsideSelfDisplay && (
                <>
                    {/*begin::Header Logo*/}
                    <div className="header-logo">
                            {user.Logo ?
                            imageBroken ? (
                                <h2 className="text-uppercase text-dark"> {user.CompanyName} </h2>
                              ) : (
                                <img
                                alt={user.CompanyName}
                                src={`https://www.diamondsoncall.com/assets/white_label/${user.Logo}`}
                                  height="50px"
                                  onError={() => setImageBroken(true)}
                                />)
                             :<h2 className="text-uppercase text-dark"> {user.CompanyName} </h2>}
                    </div>
                    {/*end::Header Logo*/} 
                </>
            )}
            {/*begin::Header Menu*/}
            <HeaderMenu layoutProps={layoutProps} />
            {/*end::Header Menu*/}
        </div>
        {/*Header Menu Wrapper*/}
    </>)
}
